<template>
  <div>
    <div class="grid-container">
      <main class="grid-item main">
        <div class="items">
          <slot></slot>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
  export default {
    name: "ScrollWrapper",
    created() {
    },
    data() {
      return {
        ev: {
          up: undefined,
          down: undefined,
          leave: undefined,
          move: undefined,
        }
      }
    },
    mounted() {
      this.setScrollTable();
    },
    methods: {
      setScrollTable() {
        this.$nextTick(() => {
          const slider = document.querySelector('.items');
          slider.style.userSelect = 'none';
          slider.style.cursor = 'pointer';

          let isDown = false;
          let startX;
          let scrollLeft;
          this.ev.down = (e) => {
            // console.log('pageX',e.pageX, slider.offsetLeft)
            isDown = true;
            slider.classList.add('active');
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
          };
          slider.addEventListener('mousedown', this.ev.down);
          this.ev.leave = () => {
            isDown = false;
            slider.classList.remove('active');
          }
          slider.addEventListener('mouseleave', this.ev.leave);
          this.ev.up = () => {
            isDown = false;
            slider.classList.remove('active');
          }
          slider.addEventListener('mouseup', this.ev.up);
          this.ev.move = (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 1; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
            // console.log(walk);
          }
          slider.addEventListener('mousemove', this.ev.move);
        })
      }
    }
  }
</script>
<style lang="stylus" scoped>
  //VARIABLES BECAUSE CSS PREPROCESSORS ARE COOL
  $gray = #555
  $white #efefef

  .grid-container
    background $white

  //ENABLE CSS GRID FOR LIFT OFF  🚀
  @supports (display grid)
    .grid-item
      color #fff
      //background skyblue
      padding 3.5em 1em
      font-size 1em

    .header
      //background-color darken(skyblue, 60%)
      grid-area header
      padding 1em

    .title
      color $gray
      //background-color lighten(skyblue, 25%)
      grid-area title

    .main
      color lighten($gray, 25%)
      //background-color lighten(skyblue, 60%)
      grid-area main
      padding 0

    .items
      position relative
      width 100%
      overflow-x scroll
      overflow-y hidden
      transition all 0.2s
      will-change transform
      -ms-overflow-style none /* IE and Edge */
      scrollbar-width none /* Firefox */

    .items::-webkit-scrollbar
      display none /* Chrome, Safari, Opera*/

    .items tr
      border-bottom 1px solid #eee

    .lp-table
      width 100%

    .items.active
      background rgba(255, 255, 255, 0.3)
      cursor grabbing
      cursor -webkit-grabbing

    .item
      text-align center
      padding 1em
      vertical-align middle
      @media screen and (max-width 500px)
        min-height 200px
        min-width 200px

</style>
